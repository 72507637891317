import { useEffect, useState } from 'react';
import { capitalize, formatAmount } from '../../../utils/formats';
import Copyable from '../../shared/copyable';
import { switchBankInfo } from '../../../utils';
import { modalStore } from '../../../state';

interface BankTransferTypes {
  bankDetails: {
    account_number: string;
    account_name: string;
    bank_name: string;
  };
  time: {
    text: string;
    late: boolean;
    waitElapsed: boolean;
    confirmPayment: boolean;
  };
}

function BankTransfer({ bankDetails, time }: BankTransferTypes) {
  const { currency, amount, stage, fees } = modalStore();
  const [isSwitched, setIsSwitched] = useState(true);

  useEffect(() => {
    let seconds = 10;

    const updateSwitch = () => {
      setIsSwitched(prevIsSwitched => !prevIsSwitched);
      seconds = 10;
    };

    const intervalId: NodeJS.Timeout = setInterval(() => {
      seconds -= 1;

      if (seconds === 0) {
        updateSwitch();
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="kpy-col__bank">
      <section className="details-head">
        <p>
          Transfer exactly{' '}
          <strong>
            {currency} {formatAmount(parseFloat(amount) + parseFloat(fees?.[stage]?.fee || 0) + parseFloat(fees?.[stage]?.vat || 0))}{' '}
          </strong>
          to the bank account below.
        </p>
      </section>
      <section className="kpy-col__channel-container">
        <p className="details-desc bank-details">
          {switchBankInfo[bankDetails?.bank_name]?.image && <img src={switchBankInfo[bankDetails?.bank_name]?.image} alt="bank logo" />}
          <span data-testid="bank-name" className="bank-name">
            {switchBankInfo[bankDetails?.bank_name]?.name || capitalize(bankDetails?.bank_name || '')}
          </span>
        </p>
        <p className="details-desc">
          <Copyable text={bankDetails?.account_number} spanClassName="account-number" copyColor="#4acfb2" />
        </p>
        <p className="details-desc">
          <span className="account-name-label">Account Name: </span>
          <span data-testid="account-name" className="account-name">
            {bankDetails?.account_name}
          </span>
        </p>

        <div className={`countdown-container ${time?.late && time.text !== 'expired' ? 'countdown-late' : ''}`}>
          {time.text !== 'expired' &&
            (isSwitched ? (
              <>
                <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM15 12H5V8H15V12Z"
                    fill="#F32345"
                  />
                </svg>
                <div className="vertical-line"></div>

                <article>
                  <span>Do not save or reuse this account number</span>
                </article>
              </>
            ) : (
              <>
                <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="8" />
                  <path
                    d="M27.5262 15.2161L28.2561 14.4862L29.0903 15.3205L30.5501 13.8606L27.4219 10.7324L25.9621 12.1923L26.7963 13.0264L25.8578 13.9649C20.8527 10.9409 14.3878 12.505 11.4682 17.5101C8.54853 22.5152 10.1126 28.7716 15.0134 31.7956C19.9143 34.8195 26.3792 33.2554 29.2988 28.2503C31.9057 23.9751 31.1758 18.5529 27.5262 15.2161ZM20.4356 30.1272C16.369 30.1272 13.1365 26.8948 13.1365 22.8281C13.1365 18.7615 16.369 15.529 20.4356 15.529V22.8281H27.7347C27.7347 26.8947 24.5023 30.1272 20.4356 30.1272Z"
                    fill="#F79E1B"
                  />
                  <path
                    d="M17.3076 8.33496V10.4204H19.3931V11.4632C20.123 11.3589 20.7486 11.3589 21.4786 11.4632V10.4204H23.564V8.33496H17.3076Z"
                    fill="#F79E1B"
                  />
                </svg>
                <div className="vertical-line"></div>

                <article>
                  <span>
                    This account{' '}
                    {time.text === 'expired' ? (
                      <strong>{time.text}</strong>
                    ) : (
                      <span>
                        expires in <strong>{time.text}</strong>
                      </span>
                    )}
                  </span>
                </article>
              </>
            ))}
        </div>
      </section>

      <section className="details-footer">
        {time.text !== 'expired' && (
          <div className={`confirmation-spinner ${time.waitElapsed ? 'visible' : ''}`}>
            <span />
          </div>
        )}
        <p>You will get a confirmation once we receive your payment.</p>
      </section>
    </div>
  );
}

export default BankTransfer;
