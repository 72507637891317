import { useEffect, useRef, useLayoutEffect, Dispatch, SetStateAction } from 'react';
import { modalStore } from '../../../state';
import { formatNumber, checkPinInputs } from '../../../utils/formats';
import { authDetailsType } from '../cardtypes';
import { debounce } from '../../../utils/utilFunctions';

interface PinformTypes {
  pin: string[];
  setAuthDetails: Dispatch<SetStateAction<authDetailsType>>;
  authorizeCharge: (e: string) => void;
}

const PinForm = ({ pin, setAuthDetails, authorizeCharge }: PinformTypes) => {
  const { testCard, update } = modalStore();
  const testPaymentInitiated = useRef(false);

  useLayoutEffect(() => {
    update({ page: 'Card PIN' });
  }, []);

  useEffect(() => {
    document.getElementById('card-pin-0')?.focus();

    if (testCard && !testPaymentInitiated.current) {
      setTimeout(() => authorizeCharge('pin'), 1000);
      testPaymentInitiated.current = true;
    }
  }, []);

  const debouncedAuthorizeCharge = useRef(debounce(() => authorizeCharge('pin'), 1000));

  useEffect(() => {
    if (pin?.join('')?.length === 4) {
      debouncedAuthorizeCharge.current();
    }
  }, [pin, authorizeCharge]);

  return (
    <div className={`kpy-col__pay-form`}>
      <p className="details-head" id="pin-information">
        Enter your 4-digit card PIN to authorize this payment
      </p>
      <section className="channel_container pin_container">
        <div className="kpy-col-input-field">
          {Array(4)
            .fill(0)
            .map((_, index) => (
              <input
                aria-label={`card pin digit ${index + 1}`}
                key={index}
                kpy-pin-input="true"
                aria-describedby={index === 0 ? 'pin-information' : ''}
                className={`kpy-col-input --card-details pin_input pin-${index}`}
                name={`card-pin-${index}`}
                id={`card-pin-${index}`}
                pattern="^[0-9]*$"
                type="password"
                inputMode="numeric"
                maxLength={1}
                readOnly={testCard}
                value={testCard ? testCard.pin[index] : pin?.[index]}
                onChange={e => {
                  setAuthDetails(prevAuthDetails => {
                    const newPin = prevAuthDetails.pin;
                    newPin[index] = formatNumber(e.target.value);

                    return {
                      ...prevAuthDetails,
                      pin: newPin
                    };
                  });
                }}
                onKeyUp={e => {
                  const key = e.keyCode;
                  checkPinInputs(key, index);
                }}
              />
            ))}
        </div>
      </section>
    </div>
  );
};

export default PinForm;
