import React, { useState, useEffect, useRef, useCallback, useLayoutEffect, Dispatch, SetStateAction } from 'react';
import { modalStore } from '../../../state';
import APIRequest from '../../../services/api-service';

import InputToolTip from '../../shared/input-tooltip';
import ListDropdown from '../../shared/list-dropdown';
import lock from '../../../assets/lock.svg';
import { authDetailsType } from '../cardtypes';
import { allowNoSymbols, cleanInput } from '../../../utils';

const api = new APIRequest();

interface AddressDetails {
  country: string;
  address: string;
  state: string;
  zip_code: string;
  city: string;
}

interface DropdownList {
  country: string[];
  state: Record<string, string>;
}
interface AddressFormType {
  addressDetails: AddressDetails;
  setAuthDetails: Dispatch<SetStateAction<authDetailsType>>;
  authorizeCharge: (e: string) => void;
}

const AddressForm = ({ addressDetails, setAuthDetails, authorizeCharge }: AddressFormType) => {
  const { update, testCard, paymentLink } = modalStore();

  useLayoutEffect(() => {
    update({ page: 'Card Address' });
  }, []);

  const [addressState, setAddressState] = useState({ disabled: false });
  const [dropdownState, setDropdownState] = useState({
    country: false,
    state: false
  });
  const [dropdownList, setDropdownList] = useState<DropdownList>({
    country: [],
    state: {}
  });
  const [isFetchingCountries, setIsFetchingCountries] = useState(true);

  const submitButtonRef = useRef<null | HTMLButtonElement>(null);
  const hasCountries = useRef(false);

  const handleDisabled = () => {
    if (testCard) return false;
    if (!addressDetails.country || !addressDetails.address || !addressDetails.state || !addressDetails.zip_code || !addressDetails.city)
      return true;
  };

  const submitAddressForm = useRef(e => {
    // Enter key triggers the authorization
    if (e.keyCode === 13) {
      const submitButton = submitButtonRef.current as HTMLButtonElement;
      if (!submitButton.classList.contains('disabled')) {
        submitButton.click();
      }
      return;
    }
  });

  const fetchCountries = useCallback(async () => {
    setIsFetchingCountries(true);
    try {
      const { data: countries } = await api.getCountries();
      if (countries) {
        const fetchedCountries: Record<string, string>[] = [];
        const fetchedStates = {};
        for (let i = 0; i < countries.length; i += 1) {
          const { name, states } = countries[i];
          fetchedCountries.push({name});
          fetchedStates[name] = states.map((state) => state);
        }
        setDropdownList(prevDropdownList => ({
          ...prevDropdownList,
          country: fetchedCountries,
          state: fetchedStates
        }));
        hasCountries.current = true;
        setIsFetchingCountries(false);
        return;
      }
      setIsFetchingCountries(false);
    } catch (error) {
      setIsFetchingCountries(false);
    }
  }, []);

  useEffect(() => {
    document.querySelector('.billing-country')?.focus();
    const submitAddress = submitAddressForm.current;
    window.addEventListener('keydown', submitAddress);

    return () => window.removeEventListener('keydown', submitAddress);
  }, []);

  useEffect(() => {
    if (addressState.disabled) {
      setTimeout(() => setAddressState({ disabled: false }), 2000);
    }
  }, [addressState.disabled]);

  useEffect(() => {
    if (!hasCountries.current && dropdownList.country.length === 0 && !testCard) {
      fetchCountries();
      return;
    }
  }, [dropdownList.country, fetchCountries, testCard]);

  useEffect(() => {
    if (hasCountries.current) {
      setAuthDetails((prevDetails: authDetailsType) => ({
        ...prevDetails,
        avs: {
          ...prevDetails.avs,
          state: ''
        }
      }));
    }
  }, [addressDetails.country, setAuthDetails]);

  return (
    <div className="kpy-col__pay-form">
      <p className="details-head">Enter your billing address to complete this payment</p>
      <section className="channel_container">
        <div className="kpy-col-input-field">
          <label htmlFor="billing-country" className="kpy-col-label">
            Country
          </label>
          <ListDropdown
            type="country"
            active={dropdownState.country}
            setActive={setDropdownState}
            value={testCard ? testCard.avs.country : addressDetails.country}
            readOnly={Boolean(testCard)}
            setValue={val =>
              setAuthDetails(prevValues => ({
                ...prevValues,
                avs: {
                  ...prevValues.avs,
                  country: val
                }
              }))
            }
            refetchList={testCard ? null : fetchCountries}
            isFetching={testCard ? false : isFetchingCountries}
            list={dropdownList.country}
            className="kpy-col-input --billing-details billing-country"
          />
        </div>

        <div className="kpy-col-input-field">
          <label htmlFor="billing-street-address" className="kpy-col-label">
            Street address
          </label>
          <input
            aria-label="billing-street-address"
            className="kpy-col-input --billing-details billing-street-address"
            id="billing-street-address"
            name="billing-street-address"
            type="text"
            placeholder="101 Burgundy Street"
            readOnly={Boolean(testCard)}
            value={testCard ? testCard.avs.address : addressDetails.address}
            onChange={e =>
              setAuthDetails(prevDetails => ({
                ...prevDetails,
                avs: {
                  ...prevDetails.avs,
                  address: cleanInput(e.target.value)
                }
              }))
            }
            onBlur={e =>
              setAuthDetails(prevDetails => ({
                ...prevDetails,
                avs: {
                  ...prevDetails.avs,
                  address: cleanInput(e.target.value)
                }
              }))
            }
          />
        </div>

        <div className="second_card_layer">
          <div className="kpy-col-input-field">
            <label htmlFor="billing-state" className="kpy-col-label">
              State
            </label>
            <ListDropdown
              type="state"
              active={dropdownState.state}
              setActive={setDropdownState}
              value={testCard ? testCard.avs.state : addressDetails.state}
              isFetching={testCard ? false : isFetchingCountries}
              setValue={val =>
                setAuthDetails(prevValues => ({
                  ...prevValues,
                  avs: {
                    ...prevValues.avs,
                    state: val
                  }
                }))
              }
              readOnly={Boolean(testCard)}
              list={testCard ? [] : dropdownList.state?.[addressDetails.country] || []}
              className="kpy-col-input --billing-details billing-state"
            />
          </div>

          <div className="kpy-col-input-field">
            <label htmlFor="billing-city" className="kpy-col-label">
              City
            </label>
            <input
              aria-label="billing-city"
              className="kpy-col-input --billing-details billing-city"
              id="billing-city"
              name="billing-city"
              type="text"
              placeholder="Portsbridge"
              readOnly={Boolean(testCard)}
              value={testCard ? testCard.avs.city : addressDetails.city}
              onChange={e =>
                setAuthDetails(prevDetails => ({
                  ...prevDetails,
                  avs: {
                    ...prevDetails.avs,
                    city: allowNoSymbols(e.target.value)
                  }
                }))
              }
              onBlur={e =>
                setAuthDetails(prevDetails => ({
                  ...prevDetails,
                  avs: {
                    ...prevDetails.avs,
                    city: allowNoSymbols(e.target.value)
                  }
                }))
              }
            />
          </div>
        </div>

        <div className="kpy-col-input-field">
          <label htmlFor="billing-zip-code" className="kpy-col-label">
            Zip/Postal Code
          </label>
          <div className="field-wrapper">
            <input
              aria-label="billing-zip-code"
              className="kpy-col-input --billing-details billing-zip-code"
              id="billing-zip-code"
              name="billing-zip-code"
              type="text"
              placeholder="12345"
              pattern="^[0-9]*$"
              readOnly={Boolean(testCard)}
              value={testCard ? testCard.avs.zip_code : addressDetails.zip_code}
              onChange={e =>
                setAuthDetails(prevDetails => ({
                  ...prevDetails,
                  avs: {
                    ...prevDetails.avs,
                    zip_code: allowNoSymbols(e.target.value)
                  }
                }))
              }
              onBlur={e =>
                setAuthDetails(prevDetails => ({
                  ...prevDetails,
                  avs: {
                    ...prevDetails.avs,
                    zip_code: allowNoSymbols(e.target.value)
                  }
                }))
              }
            />
            <InputToolTip
              type="billing-zip-code"
              full
              message={<p id="billing-zip-code-input-tooltip">{`Enter the Zip/Postal code for your card's billing address.`}</p>}
            />
          </div>
        </div>
      </section>

      <button
        className={`kpy-col-btn address-form-btn ${handleDisabled() || addressState.disabled ? 'disabled' : ''}`}
        ref={submitButtonRef}
        onClick={() => {
          if (handleDisabled() || addressState.disabled) return;
          authorizeCharge('avs');
          setAddressState({ disabled: true });
        }}
        style={{
          background: paymentLink?.checkout_customization?.primary_color,
          color: paymentLink?.checkout_customization?.secondary_color
        }}
        aria-disabled={handleDisabled() || addressState.disabled}
      >
        <img src={lock} alt="lock icon" aria-hidden />
        <span>Complete payment</span>
      </button>
    </div>
  );
};

export default AddressForm;
