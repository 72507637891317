import { useState, useRef, useLayoutEffect } from 'react';
import BankTransfer from './index';
import { modalStore, sendMessage } from '../../state';
import BankTransferIntro from './bank-transfer-actions/bank-transfer-intro';
import APIRequest from '../../services/api-service';
import BankTransferHelp from './bank-transfer-actions/bank-help';

const api = new APIRequest();

const BankTransferHome = () => {
  const { bankDetails, public_key, payment_reference, stage, update, sessionAttempt, unique_reference } = modalStore();
  useLayoutEffect(() => {
    if (stage === 'bank_transfer_help_actions') {
      setBankState('help');
    }
  }, [stage]);
  const paymentSession = useRef(false);
  const [bankState, setBankState] = useState('');
  const [understand, setUnderstand] = useState(false);
  const activatePaymentSession = () => (paymentSession.current = true);
  const handleClickAction = () => {
    setUnderstand(true);
    if (!bankDetails) {
      update({
        sessionAttempt: sessionAttempt + 1
      });
      sendMessage(
        'INIT_CHARGE_BANKTRANSFER',
        async () => {
          const response = await api.initBankCharge({
            type: 'bank_transfer',
            data: {
              public_key,
              payment_reference
            }
          });
          if (!paymentSession.current) {
            activatePaymentSession();
          }
          return response;
        },
        <span>
          Generating payment information <br /> Please wait...
        </span>
      );
      api.updateSession({ key: unique_reference, attempt: +sessionAttempt + 1 });
    }
  };

  const handleGoBack = () => {
    setBankState('');
    update({
      buttonAction: 'helpActions',
      stage: 'bank_transfer',
      hideHeading: false
    });
  };

  switch (bankState) {
    case 'help':
      return <BankTransferHelp goBack={handleGoBack} />;

    default:
      return understand ? <BankTransfer /> : <BankTransferIntro handleClickUnderstand={handleClickAction} />;
  }
};

export default BankTransferHome;
