import { modalStore, sendMessage } from '../../../state';
import APIRequest from '../../../services/api-service';
import { Mixpanel } from '../../../utils';

const api = new APIRequest();

const DeleteCard = () => {
  const { tokenisedcardreference, last_four, browser_card_token, public_key, page } = modalStore();

  const deleteCard = () => {
    Mixpanel.track('User attempts to delete a card', {
      'Source Page': `${page} page`
    });
    return sendMessage('DELETE_SAVEDCARD', () =>
      api.deleteSavedCard({
        card_reference: tokenisedcardreference,
        browser_card_token,
        public_key
      })
    );
  };
  return (
    <div className="delete-card-wrapper">
      <h1>Remove saved card</h1>
      <p>
        Are you sure you want to remove your card with <span>**** {last_four}?</span>
      </p>
      <button onClick={() => deleteCard()}>Yes, remove card</button>
    </div>
  );
};

export default DeleteCard;
