import React, { useLayoutEffect, useEffect } from 'react';
import { useParams, useLocation} from 'react-router-dom';
import { parse } from 'flatted';
import { BroadcastChannel } from 'broadcast-channel';
import APIRequest from '../services/api-service';
import { modalStore, sendMessage } from '../state';

import Feedback from '../components/feedback';
import StarthereHeader from '../components/shared/start-here-header';
import loaderIcon from '../assets/kpy-loader.svg';
import { handleTabRedirect } from '../services/external-hooks';
import { environments } from '../utils';
import '../style/main.scss';

interface RouteParams {
  transactionReference: string;
}

const api = new APIRequest();

function CardChargeVerification() {
  const { transactionReference: reference } = useParams<RouteParams>();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const merchantOrderId = query.get('merchant_order_id');
  const { feedback, loader, modalState, update, redirect_url, channel } = modalStore();
  const hideFeedback = Boolean(channel === 'api' && redirect_url);

  useLayoutEffect(() => {
    update({ page: 'Charge Verification' });
  }, []);

  useLayoutEffect(() => {
    sendMessage('VERIFY_CARD_CHARGE', () =>
      api.queryCardCharge({
        reference: reference || merchantOrderId,
        env: process.env.VITE_ENV_TYPE
      })
    );

    const broadcast = new BroadcastChannel('KPY_ATHENA');
    broadcast.onmessage = event => {
      const parsedData = parse(event);
      const threedsWindow = parsedData.data;
      if (!hideFeedback && feedback) {
        setTimeout(() => {
          threedsWindow.close();
        }, 5000);
      }
    };
  }, [reference, hideFeedback, feedback]);

  // Only applicable if we load the Processor's 3DS page on the same tab
  // useEffect(() => {
  //   if (channel === "modal") {
  //     // Close Enclosing Iframe for the Checkout only
  //     if (isInFrame() && hasAccessToWindow(window)) {
  //       window?.parent?.document
  //         .querySelector(".threeds-modal--close-btn")
  //         ?.click();
  //     }
  //   }
  // }, [channel, card]);

  useEffect(() => {
    if (hideFeedback) handleTabRedirect();
  }, [hideFeedback]);

  return (
    <>
    {modalState === 'success' && (
        <StarthereHeader/>
      )}
      <div className="kpy__root-container">
        {modalState !== 'init' && !hideFeedback && (
          <div className="secured__banner">
            <img src="https://korablobstorage.blob.core.windows.net/modal-bucket/Safety.svg" alt="" />
            <p>
              Secured by <strong>Korapay</strong>
            </p>
          </div>
        )}
        <div className="kpy-col__container">
          {!hideFeedback && (
            <>
              {process.env.VITE_ENV_TYPE === environments.TEST && (
                <div className="test-mode-indicator">
                  <p>TEST MODE</p>
                </div>
              )}
              {feedback && <Feedback disableOutgoing={channel === 'modal'} />}
            </>
          )}
          <div className={loader || hideFeedback ? 'kpy--modal--inner display-block' : 'kpy--modal--inner display-none'}>
            <div className="kpy--modal-content">
              <div className="kpy--modal--loader kpy--loader">
                <img src={loaderIcon} alt="Loading" />
                <p>Verifying your payment...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CardChargeVerification;
