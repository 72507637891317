import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { modalStore, sendMessage } from '../../../state';
import APIRequest from '../../../services/api-service';
import { allowNoSymbols, Mixpanel, switchBanks, SwitchBanksTypes, calculateOTPTime } from '../../../utils';
import checkmark from '../../../assets/checkmark-green.svg';
import loader from '../../../assets/kpy-loader.svg';

const api = new APIRequest();

export default function BankOtp() {
  const { isOtpIncorrect, errorMessage, otpMessage, update, public_key, reference, bank_slug, canResendOtp, page, validateAccount } =
    modalStore();

  useLayoutEffect(() => {
    update({ page: 'Pay with bank authorisation' });
  }, []);

  const [otpState, setOtpState] = useState({ disabled: false });
  const [openInfo, setOpenInfo] = useState(false);
  const [otp, setOtp] = useState('');
  const submitButtonRef = useRef(null);
  const newOtpSessionRef = useRef(false);
  const timerInterval = useRef(null);
  const bankStyle = switchBanks[bank_slug as keyof SwitchBanksTypes];
  const [resendOtpState, setResendOtpState] = useState({
    loading: false,
    feedback: false,
    activeResendCountdown: true,
    countdownTime: '',
    resendLimitExceeded: false
  });

  const submitOtpForm = useRef(e => {
    // Enter key triggers the authorization
    if (e.keyCode === 13) {
      const submitButton = submitButtonRef.current;
      if (!submitButton?.classList.contains('disabled')) {
        submitButton?.click();
      }
      return;
    }
  });

  const resetErrorMessage = useRef(() => {
    update({
      isOtpIncorrect: false,
      errorMessage: ''
    });
    newOtpSessionRef.current = false;
  });

  const authorizeOtp = () => {
    if (otp.length < 4 || otpState.disabled) return;
    if (isOtpIncorrect && newOtpSessionRef.current) {
      resetErrorMessage.current();
    }

    Mixpanel.track('pay with bank authorisation', null);
    sendMessage(
      'AUTHORIZE_BANK_DEBIT',
      () =>
        api.authorizeBankDebit({
          public_key,
          reference,
          authorization: {
            [isKudaMFB ? 'payId' : 'otp']: otp
          }
        }),
      <>
        Processing your payment <br /> Please wait ...
      </>
    );

    setOtpState({ disabled: true });
    setOtp('');
  };

  const resendOtp = async () => {
    Mixpanel.track('Resend OTP Attempt', {
      'Source Page': `${page} page`
    });
    // Activates the retry state for a new otp
    if (!resendOtpState.loading) {
      setResendOtpState(prevResendOtpState => ({
        ...prevResendOtpState,
        loading: true
      }));
    }
    try {
      const response = await api.resendPayWithBankOtp({
        reference
      });
      if (response?.message.includes('Exceeded')) {
        setResendOtpState(prevResendOtpState => ({
          ...prevResendOtpState,
          resendLimitExceeded: true
        }));
        return;
      }

      if (response?.data?.error_type || !response?.data?.status) {
        setResendOtpState(prevResendOtpState => ({
          ...prevResendOtpState,
          loading: false,
          activeResendCountdown: false
        }));
        return;
      }
      setResendOtpState(prevResendOtpState => ({
        ...prevResendOtpState,
        loading: false,
        feedback: true,
        activeResendCountdown: true
      }));
    } catch (error) {
      setResendOtpState(prevResendOtpState => ({
        ...prevResendOtpState,
        loading: false,
        activeResendCountdown: false
      }));
    }
  };

  useEffect(() => {
    const submitOtp = submitOtpForm.current;
    window.addEventListener('keydown', submitOtp);

    (document.getElementById('bank-otp') as HTMLInputElement).focus();
    return () => window.removeEventListener('keydown', submitOtp);
  }, []);

  useEffect(() => {
    if (isOtpIncorrect) {
      setTimeout(() => (document.querySelector('.details-error') as HTMLElement)?.focus());
      setTimeout(() => (newOtpSessionRef.current = true));
    }
  }, [isOtpIncorrect, update]);

  useLayoutEffect(() => {
    if (resendOtpState.activeResendCountdown) {
      const deadline = new Date();
      deadline.setSeconds(deadline.getSeconds() + 31);

      timerInterval.current = setInterval(() => {
        const timerValue = calculateOTPTime(deadline);
        if (timerValue === 'expired') {
          clearInterval(timerInterval.current);
          setResendOtpState(prevResendOtpState => ({
            ...prevResendOtpState,
            activeResendCountdown: false,
            countdownTime: ''
          }));
          return;
        }
        return setResendOtpState(prevResendOtpState => ({
          ...prevResendOtpState,
          countdownTime: timerValue
        }));
      }, 1000);
    }

    return () => clearInterval(timerInterval.current);
  }, [resendOtpState.activeResendCountdown]);

  useEffect(() => {
    if (resendOtpState.feedback) {
      setTimeout(
        () =>
          setResendOtpState(prevResendOtpState => ({
            ...prevResendOtpState,
            feedback: false
          })),
        1500
      );
    }
  }, [resendOtpState.feedback]);

  useEffect(() => {
    if (isOtpIncorrect && newOtpSessionRef.current) {
      resetErrorMessage.current();
    }
  }, [otp, isOtpIncorrect]);

  const isKudaMFB = bank_slug === 'kuda-mfb';
  const isZenith = bank_slug === 'zenith';

  const handleToggle = () => {
      setOpenInfo(!openInfo);
    };

  const bankActionHeader = () => {
    if (isZenith) {
      if (validateAccount) {
        return 'Validate your account';
      }
      return 'Authorize your payment';
    }
    return null;
  };

  const sendRequest = () => {
    if (resendOtpState?.resendLimitExceeded) {
      return <p>Resend limit exceeded</p>;
    }
    if (resendOtpState.feedback) {
      return (
        <p className="resend-otp-feedback">
          <img src={checkmark} alt="success icon" />
          OTP Resent
        </p>
      );
    }

    return (
      <p>
        Didn’t receive any OTP?{' '}
        {!resendOtpState.activeResendCountdown ? (
          <button
            className={`resend-otp-btn ${resendOtpState.loading ? 'disabled' : ''}`}
            onClick={!resendOtpState.loading ? resendOtp : null}
            aria-disabled={resendOtpState.loading}
          >
            Resend
            {resendOtpState.loading && (
              <>
                {'ing'}
                <img src={loader} alt="loading icon" aria-hidden />
              </>
            )}
          </button>
        ) : (
          <span className="resend-otp-countdown">Wait {resendOtpState.countdownTime || '30 seconds'}</span>
        )}
      </p>
    );
  };

  return (
    <div className="kpy-col__pay-form --centered">
      {bankActionHeader() && <h1 className="details-head">{bankActionHeader()}</h1>}
      <p data-testid="detail-title" className="details-head">
        {isKudaMFB ? 'Enter the Pay ID generated from your Kuda Bank app to authorize this payment.' : otpMessage}
      </p>
      {isOtpIncorrect && !isZenith && (
        <p className="details-error" tabIndex={-1}>
          {isKudaMFB ? 'The Pay ID you entered is incorrect. Please try again.' : errorMessage}
        </p>
      )}
      {isKudaMFB && (
        <p onClick={handleToggle} style={{ color: '#2376F3', cursor: 'pointer', fontSize: '14px' }} className="details-head">
          How to generate a Pay ID
        </p>
      )}
      {openInfo && (
        <div className={`kpy-col__input-tooltip--message normal-width dark visible position`}>
          <div className="details-head input-tooltip visible">
            <div className="kuda-payid-info" id="billing-zip-code-input-tooltip">
              <p>
                <span>
                  <strong>How to generate your Pay ID on Kuda</strong>
                </span>
                <button className="close-icon" aria-label="close tooltip" onClick={handleToggle}>
                  &times;
                </button>
              </p>

              <ol>
                <li>
                  <span>1</span> <p> Open your Kuda Bank app.</p>
                </li>
                <li>
                  <span>2</span>
                  <p>
                    Go to the <strong>“Pay”</strong> tab (bottom navigation).
                  </p>
                </li>
                <li>
                  <span>3</span>
                  <p>
                    Select the <strong>“Cardless Payments”</strong> option.
                  </p>
                </li>
                <li>
                  <span>4</span>{' '}
                  <p>
                    Select either <strong>“Pay with Bank”</strong> or <strong>"Pay A Kuda Business”.</strong>
                  </p>
                </li>
                <li>
                  <span>5</span>
                  <p>
                    {' '}
                    Click on <strong>“Make Payment”.</strong>
                  </p>
                </li>
                <li>
                  <span>6</span>
                  <p>Copy the generated Payer ID and paste it here.</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      )}
      <section className="channel_container" style={bankStyle && { backgroundColor: bankStyle.accent }}>
        <div className="kpy-col-input-field">
          <label htmlFor="bank-otp" className="kpy-col-label" style={bankStyle && { color: bankStyle.color }}>
            {isKudaMFB ? 'Enter Pay ID' : 'Enter One-time PIN'}
          </label>

          <div className="field-wrapper">
            <input
              aria-label="bank-otp"
              aria-describedby="bank-otp-message"
              className={`kpy-col-input otp_input ${isOtpIncorrect && 'error'}`}
              name="auth"
              id="bank-otp"
              type="password"
              maxLength={6}
              pattern="^[0-9]*$"
              inputMode="numeric"
              value={otp}
              onChange={event => {
                setOtp(allowNoSymbols(event.target.value));
                setOtpState({ disabled: false });
              }}
              placeholder="******"
            />
            {isOtpIncorrect && isZenith && <p className="otp-error">{errorMessage}</p>}
          </div>
        </div>
      </section>
      <button
        className={`kpy-col-btn otp-form-btn ${otp.length < 4 || otpState.disabled ? 'disabled' : ''}`}
        ref={submitButtonRef}
        style={bankStyle && { backgroundColor: bankStyle.color }}
        aria-disabled={otp.length < 4 || otpState.disabled}
        onClick={authorizeOtp}
      >
        <span>{validateAccount ? 'Validate' : 'Authorize'}</span>
      </button>
      {canResendOtp && <div className="retry-auth">{sendRequest()}</div>}
    </div>
  );
}
