import { useRef, useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';

import caret from '../../assets/caret.svg';
import search from '../../assets/search.svg';
import loader from '../../assets/kpy-loader.svg';
import reload from '../../assets/reload.svg';
import closeIcon from '../../assets/close.svg';
import { debounce } from '../../utils/utilFunctions';
import { switchBanks } from '../../utils';
import { TBankList } from '../bank/bank-types';

interface ActiveType {
  country: boolean;
  state: boolean;
}
interface ListDropDownTypes {
  type: string;
  active: boolean;
  setActive: Dispatch<SetStateAction<{ bank: boolean; }>>;
  setValue: (e: string) => void;
  value?: string;
  list: TBankList[];
  className: string;
  readOnly?: boolean;
  refetchList?: (() => Promise<void>) | null;
  finalItem?: ReactNode;
  isFetching: boolean;
  showFinalItemAsDefault?: boolean;
}

function ListDropdown({
  refetchList,
  readOnly = false,
  finalItem = '',
  showFinalItemAsDefault = false,
  type,
  active,
  setActive,
  setValue,
  value,
  isFetching,
  list,
  className
}: ListDropDownTypes) {
  const dropdownOverlayRef = useRef(null);
  const dropdownButtonRef = useRef<null | HTMLButtonElement>(null);
  const dropdownMenuRef = useRef(null);
  const dropdownListRef = useRef<null | HTMLUListElement>(null);
  const dropdownInputRef = useRef(null);
  const closeButtonRef = useRef<null | HTMLButtonElement>(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<TBankList[]>(list);


  const searchList = useRef(
    debounce((query: string, originalList: string[]) => {
      if (query.trim() === '') {
        setIsSearching(false);
        return;
      }
      setIsSearching(true);
      setSearchResults(() => {
        const newList = originalList.filter((resultItem: TBankList) => resultItem?.name?.toLowerCase().includes(query.trim().toLowerCase()));
        return newList;
      });
      return;
    }, 800)
  );

  useEffect(() => {
    const dropdownOverlay = dropdownOverlayRef.current;
    if (dropdownOverlay && active) {
      closeButtonRef.current?.focus();
      return;
    }
    dropdownButtonRef?.current?.focus();
    setSearchQuery('');
    setIsSearching(false);
  }, [active]);

  useEffect(() => {
    if (!searchList?.current) return; //added this line to fix the error in test
    searchList?.current(searchQuery, list);
  }, [searchQuery, list]);

  const handleKeyboard = useRef(e => {
    const path = e?.path || e?.composedPath?.() || e.target;
    // Escape button closes the dropdown overlay
    if (e.keyCode === 27) {
      setActive(prevActive => ({
        ...prevActive,
        [type]: false
      }));
      return;
    }
    // Handle searching on letter click
    if (dropdownOverlayRef?.current && !path.includes(dropdownInputRef?.current)) {
      // Handle adding text
      if (e.keyCode >= 65 && e.keyCode <= 90) {
        setSearchQuery(prevSearchQuery => `${prevSearchQuery}${e.key}`);
        return;
      }
      // Handle deleting text
      if (e.keyCode === 8 || e.keyCode === 46) {
        setSearchQuery(prevSearchQuery => prevSearchQuery.slice(0, prevSearchQuery.length - 1));
        return;
      }
    }
    // Spacebar and Down button open the tooltip
    if (e.target === dropdownButtonRef.current && (e.keyCode === 32 || e.keyCode === 40)) {
      setActive(prevActive => ({
        ...prevActive,
        [type]: true
      }));
      return;
    }
    // Trapping tabbing inside overlay body
    if (dropdownOverlayRef?.current) {
      const lastItem = dropdownListRef.current?.childNodes[dropdownListRef.current.childNodes.length - 1].childNodes[0];
      // Tabbing backwards inside the overlay at the first focusable item
      if (e.shiftKey && e.keyCode === 9 && e.target === closeButtonRef.current) {
        dropdownListRef?.current?.focus();
        return;
      }
      // Tabbing forward inside the overlay at the last focusable item
      if (e.keyCode === 9 && path.includes(dropdownListRef?.current) && e.target === lastItem) {
        closeButtonRef.current?.focus();
        return;
      }
    }
    // Keyboard Actions in Dropdown List
    if (path.includes(dropdownListRef?.current)) {
      // Spacebar on button selects the item and closes dropdown
      if (e.keyCode === 32) {
        const buttonValue = e.target.textContent;
        setValue(buttonValue);
        setActive(prevActive => ({
          ...prevActive,
          [type]: false
        }));
        return;
      }
      // Up arrow key aids navigation
      if (e.keyCode === 38) {
        const currentListItem = e.target.parentNode;
        const previousOption = currentListItem?.previousSibling?.childNodes[0];
        if (!previousOption) {
          return;
        }
        previousOption.focus();
        return;
      }
      // Down arrow key aids navigation
      if (e.keyCode === 40) {
        const currentListItem = e.target.parentNode;
        const nextOption = currentListItem?.nextSibling?.childNodes[0];
        if (!nextOption) {
          return;
        }
        nextOption.focus();
        return;
      }
    }
  });

  const closeOuter = useRef(e => {
    const path = e?.path || e?.composedPath?.() || e.target;
    // Handles clicking on the overlay on mobile & clicking outside on desktop
    if (
      (e.target === dropdownOverlayRef?.current && !path.includes(dropdownMenuRef.current)) ||
      (dropdownOverlayRef?.current && !path.includes(dropdownOverlayRef.current) && !path.includes(dropdownButtonRef.current))
    ) {
      setActive(prevActive => ({
        ...prevActive,
        [type]: false
      }));
    }
  });

  useEffect(() => {
    const closeOuterFunc = closeOuter.current;
    const handleKeyboardFunc = handleKeyboard.current;

    // Clicking outside closes the tooltip
    window.addEventListener('click', closeOuterFunc);
    window.addEventListener('keydown', handleKeyboardFunc);

    return () => {
      window.removeEventListener('click', closeOuterFunc);
      window.removeEventListener('keydown', handleKeyboardFunc);
    };
  }, []);

  const searchingList = () => {
    const currentList = isSearching ? searchResults : list;
    return (
      <>
        {currentList?.length > 0 ? (
          currentList.map((resultItem: TBankList) => {
         return (
            <li key={resultItem?.name} role="presentation">
              <button
                onClick={() => {
                  setValue(resultItem?.name);
                  setActive(prevActive => ({
                    ...prevActive,
                    [type]: false
                  }));
                }}
                className={`button-div ${resultItem?.name?.toLowerCase() === value?.toLowerCase() ? 'active' : ''}`}
              >
                <img width={20} src={switchBanks[resultItem?.slug]?.image} alt="" />
                <span>{resultItem?.name}</span>
              </button>
            </li>
          )})
        ) : (
          <li>
            <p>No results</p>
          </li>
        )}
        {finalItem}
      </>
    );
  };

  return (
    <>
      {!isFetching && list?.length === 0 && showFinalItemAsDefault ? (
        <>{finalItem}</>
      ) : (
        <button
          onClick={() => {
            if (list?.length !== 0) {
              setActive(prevActive => ({
                ...prevActive,
                [type]: !prevActive[type as keyof ActiveType]
              }));
            }
          }}
          data-testid="list-button"
          aria-haspopup="listbox"
          aria-disabled={!readOnly && list?.length === 0}
          ref={dropdownButtonRef}
          aria-expanded={active}
          className={`${className} kpy-col__list-dropdown ${active ? 'active' : ''} ${!readOnly && list?.length === 0 ? 'disabled' : ''}`}
        >
          <img src={search} alt="search icon" aria-hidden />
          <span>{value || `Find my ${type}`}</span>
          {isFetching && <span className="sr-only">Loading</span>}
          {refetchList && list.length === 0 && (
            <div
              role="button"
              onClick={refetchList}
              className="list-dropdown--refetch"
              aria-label={`Refetch ${type} list`}
              hidden={isFetching}
            >
              <img src={reload} alt="refetch icon" hidden={!active} aria-hidden />
            </div>
          )}
          {value ? (
            <img onClick={() => setValue('')} src={closeIcon} alt="cancel icon" hidden={isFetching} aria-hidden />
          ) : (
            <img className="caret-icon" src={caret} alt="caret icon" hidden={isFetching} aria-hidden />
          )}

          {/* Loader */}
          {isFetching && list?.length === 0 && <img src={loader} className="list-dropdown--loader" alt="loading icon" aria-hidden />}
        </button>
      )}

      {active && (
        <div className="kpy-col__dropdown-overlay" ref={dropdownOverlayRef}>
          <div className="kpy-col__dropdown-menu" ref={dropdownMenuRef}>
            <button
              className="sr-only"
              ref={closeButtonRef}
              onClick={() => {
                setActive(prevActive => ({
                  ...prevActive,
                  [type]: false
                }));
              }}
            >
              Close Dropdown Menu
            </button>
            <div className="kpy-col__dropdown-search">
              <input
                aria-label={`Search ${type} dropdown list`}
                placeholder={`Select a ${type}`}
                className="kpy-col__dropdown-input"
                ref={dropdownInputRef}
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                onBlur={e => setSearchQuery(e.target.value)}
              />
              <img src={search} alt="search icon" aria-hidden />
            </div>
            <p className="sr-only" id="dropdown-list">
              {type} dropdown menu list.
            </p>
            <ul
              className="kpy-col__dropdown-list"
              role="listbox"
              data-testid="dropdown-list"
              aria-label="dropdown-list"
              aria-labelledby="dropdown-list"
              aria-busy="true"
              tabIndex={0}
              ref={dropdownListRef}
            >
              {searchingList()}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default ListDropdown;
