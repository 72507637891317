import { useState, useEffect } from 'react';
import copyIcon from '../../assets/copy-icon--light.svg';
import { copyWithJS } from '../../utils';

interface CopyableProps {
  prefix?: string;
  text?: string;
  showOnHover?: boolean;
  copyText?: string;
  copyColor?: string;
  spanClassName?: string;
  buttonClassName?: string;
  showCopyText?: boolean;
  showIcon?: boolean;
  textModifier?: (e: string) => string;
}

const Copyable = ({
  text = 'Not Available',
  showOnHover = false,
  copyText = '',
  copyColor = '#4acfb2',
  spanClassName = '',
  buttonClassName = '',
  showCopyText = false,
  textModifier = x => x,
  prefix,
  showIcon
}: CopyableProps) => {
  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState('');
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setValue(textModifier(text));
  }, [text]);

  return (
    <span
      role="button"
      tabIndex={0}
      className={`copyable__comp ${spanClassName}`}
      style={copied ? { color: copyColor } : {}}
      onMouseEnter={() => {
        if (showOnHover) {
          setHovered(true);
          const newValue = `${value.substr(0, value.length - 5)}...`;
          setValue(newValue);
        }
      }}
      onMouseLeave={() => {
        if (showOnHover) {
          setHovered(false);
          setValue(textModifier(text));
        }
      }}
      onClick={() => {
        copyWithJS(
          text,
          (function _() {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
              setHovered(false);
              setValue(textModifier(text));
            }, 800);
          })()
        );
      }}
    >
      {copied ? (
        copyText || 'Copied!'
      ) : (
        <>
          {value}
          {(hovered || !showOnHover) && (
            <button type="button" className={`copy-button ${buttonClassName}`} style={{ border: 'none' }} hidden={text === 'Not Available'}>
              {showIcon && <img data-testid="icon" className="copy-icon" id="copy-icon" src={copyIcon} alt="" />}
              {showCopyText && <span data-testid="copy">Copy</span>}
            </button>
          )}
        </>
      )}
    </span>
  );
};

export default Copyable;
