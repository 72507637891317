import React from 'react';
import { useNavigate } from 'react-router-dom';
import { modalStore } from '../state';
import { handleOutgoing } from '../services/external-hooks';
import { Mixpanel, onVerifyPage } from '../utils';

export default function Footer() {
  const { buttonAction, update, stage, page, card, payment_reference, allowMultipleChannels, merchant, sessionAttempt } = modalStore();

  const history = useNavigate();
  const sessionlimit = process.env.VITE_SESSION_ATTEMPT_LIMIT || 5;

  interface SwitchHelpType {
    bank_transfer: string;
    pay_with_bank: string;
  }

  const switchHelp: SwitchHelpType = {
    bank_transfer: 'bank_transfer_help_actions',
    pay_with_bank: 'bank_help_actions'
  };

  return (
    <div className="kpy-col__footer" data-testid="footer" hidden={buttonAction === 'none'}>
      {buttonAction === 'otherOptions' && (
        <div className="button-container --other">
          <button
            className="kpy-feedback-btn"
            onClick={() => {
              if (onVerifyPage(card.channel)) {
                history(`/${payment_reference}/pay`);
              }
              Mixpanel.track('Try again selected', {
                'Source Page': `${page} page`
              });
              if (sessionAttempt > sessionlimit) {
                update({
                  modalState: 'session-end'
                });
                return;
              }
              if (stage === 'card') {
                update({
                  modalState: 'loaded',
                  feedback: false,
                  buttonAction: 'changePayment',
                  hideHeading: false,
                  pay_with_bank: null,
                  card: {
                    ...card,
                    stage: 'init',
                    cardType: ''
                  }
                });
                return;
              }
              if (stage === 'pay_with_bank') {
                update({
                  modalState: 'loaded',
                  feedback: false,
                  stage: 'pay_with_bank',
                  buttonAction: 'changePayment',
                  pay_with_bank: null,
                  hideHeading: false,
                  headingImage: null
                });
                return;
              }
              update({
                modalState: 'loaded',
                feedback: false,
                stage: 'init',
                buttonAction: 'close',
                pay_with_bank: null,
                hideHeading: true,
                headingImage: null,
                testCard: null,
                sandboxInstructions: null
              });
            }}
          >
            Try again
          </button>
          <button
            className="kpy-feedback-btn --close-btn"
            onClick={() => {
              if (onVerifyPage(card.channel)) {
                history(`/${payment_reference}/pay`);
              }
              Mixpanel.track('Closed modal', {
                'Source Page': 'Modal failure page'
              });
              update({
                modalState: 'session-end'
              });
              handleOutgoing('close', null);
            }}
          >
            Cancel
          </button>
        </div>
      )}
      {buttonAction === 'helpActions' && (
        <div className="button-container --flex">
          {allowMultipleChannels && page !== 'Termination' && (
            <button
              className={`kpy-feedback-btn --change-payment-btn`}
              onClick={() => {
                Mixpanel.track('Attempted to switch payment method', {
                  'Source Page': `${page} page`
                });
                update({
                  stage: 'init',
                  buttonAction: 'close',
                  hideHeading: true,
                  headingImage: null,
                  isOtpIncorrect: false,
                  pay_with_bank: null,
                  sandboxInstructions: null,
                  default_channel: null
                });
              }}
            >
              Change payment method
            </button>
          )}
          <button
            className="kpy-feedback-btn --close-btn"
            onClick={() => {
              Mixpanel.track('Close button selected', {
                'Source Page': `${page} page`
              });
              update({
                modalState: 'session-end'
              });
              handleOutgoing('close', null);
            }}
          >
            Cancel
          </button>
          <button
            className="kpy-feedback-btn"
            data-testid="help-btn"
            onClick={() => {
              Mixpanel.track('Help selected', {
                'Source Page': `${page} page`
              });
              update({
                buttonAction: 'none',
                stage: switchHelp[stage as keyof SwitchHelpType],
                hideHeading: true
              });
            }}
          >
            Help?
          </button>
        </div>
      )}

      {buttonAction === 'changePayment' && (
        <div className="button-container --other">
          {allowMultipleChannels && (
            <button
              className={`kpy-feedback-btn ${stage === 'card' && card?.stage === 'init' ? 'card-init' : ''}`}
              onClick={() => {
                Mixpanel.track('Attempted to switch payment method', {
                  'Source Page': `${page} page`
                });
                update({
                  stage: 'init',
                  buttonAction: 'close',
                  testCard: null,
                  isCardDetailsIncorrect: false,
                  sandboxInstructions: null,
                  isOtpIncorrect: false,
                  hideHeading: true,
                  headingImage: null,
                  pay_with_bank: null,
                  card: {
                    ...card,
                    stage: 'init',
                    cardType: ''
                  }
                });
              }}
            >
              Change payment method
            </button>
          )}
          <button
            className="kpy-feedback-btn --close-btn"
            onClick={() => {
              Mixpanel.track('Closed Modal', {
                'Source Page': `${page} page`
              });
              update({
                modalState: 'session-end'
              });
              handleOutgoing('close', null);
            }}
          >
            Cancel
          </button>
        </div>
      )}

      {buttonAction === 'savedCard' && (
        <div className="button-container --other">
          <button
            className={`kpy-feedback-btn ${stage === 'card' && card?.stage === 'init' ? 'card-init' : ''}`}
            onClick={() => {
              Mixpanel.track('Switched from saved card view to input new card details', {
                'Source Page': `${page} page`
              });
              update({
                stage: 'card',
                buttonAction: 'changePayment',
                testCard: null,
                isCardDetailsIncorrect: false,
                sandboxInstructions: null,
                isOtpIncorrect: false,
                hideHeading: false,
                headingImage: null,
                pay_with_bank: null,
                card: {
                  ...card,
                  stage: 'init',
                  cardType: ''
                }
              });
            }}
          >
            Use new card
          </button>
          <button
            className="kpy-feedback-btn --close-btn"
            onClick={() => {
              Mixpanel.track('Closed Modal', {
                'Source Page': `${page} page`
              });
              update({
                modalState: 'session-end'
              });
              handleOutgoing('close', null);
            }}
          >
            Cancel
          </button>
        </div>
      )}

      {buttonAction === 'backToSavedCards' && (
        <div className="button-container">
          <button
            className="kpy-feedback-btn back-btn"
            onClick={() => {
              Mixpanel.track('Switched from delete cards back to saved cards', {
                'Source Page': `${page} page`
              });
              update({
                card: {
                  stage: 'savedcards'
                },
                buttonAction: 'savedCard'
              });
            }}
          >
            Back
          </button>
        </div>
      )}

      {buttonAction === 'close' && (
        <div className="button-container">
          <button
            className="kpy-feedback-btn --close-btn"
            onClick={() => {
              Mixpanel.track('Close button selected', {
                'Source Page': `${page} page`
              });
              update({
                modalState: 'session-end'
              });
              handleOutgoing('close', null);
            }}
          >
            {stage === 'init' ? 'Cancel Payment' : 'Cancel'}
          </button>
        </div>
      )}
      {buttonAction === 'contact' && (
        <div className="button-container">
          <button
            className="kpy-feedback-btn contact-btn"
            onClick={() => {
              Mixpanel.track('contact merchant button clicked', {
                'Source Page': `${page} page`
              });
            }}
          >
            <a style={{ textDecoration: 'none' }} href={'mailto:' + merchant?.email}>
              Contact merchant
            </a>
          </button>
        </div>
      )}
    </div>
  );
}
