import React, { useState, useEffect, useRef } from 'react';
import APIRequest from '../../../services/api-service';
import timeIcon from '../../../assets/timer-icon.svg';
import { modalStore, sendMessage } from '../../../state';
import { otpRegex, calculateTime } from '../../../utils/formats';
import lock from '../../../assets/lock.svg';

const api = new APIRequest();

export default function SandboxCheckTransaction({ retryRequest }) {
  const {
    isOtpIncorrect,
    payment_reference,
    errorMessage,
    update,
    reference,
    public_key,
    resendLimitExceeded,
    canResendStk,
    mobileMoneySecondLapse
  } = modalStore();

  const [otpState, setOtpState] = useState({
    disabled: false,
    activeRetry: false
  });
  const submitButtonRef = useRef(null);
  const newOtpSessionRef = useRef(false);
  const timerInterval = useRef(null);
  const paymentConfirmed = useRef(false);

  const [auth, setAuth] = useState(false);
  const [authDetails, setAuthDetails] = useState({
    reference: payment_reference,
    otp: '',
    phone: ''
  });
  const [time, setTime] = useState(calculateTime(new Date(mobileMoneySecondLapse)));
  useEffect(() => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
    }

    const startTime = new Date();
    timerInterval.current = setInterval(() => {
      const timerValue = calculateTime(new Date(mobileMoneySecondLapse), startTime);
      if (timerValue.text === 'expired') {
        clearInterval(timerInterval.current);
      }
      return setTime(timerValue);
    }, 1000);

    paymentConfirmed.current = false;

    return () => clearInterval(timerInterval.current);
  }, [mobileMoneySecondLapse]);

  const processPayment = () => {
    sendMessage('CONFIRM_SANDBOX_MOBILE_MONEY', () =>
      api.authOTPSandboxMobileMoneyCharge({
        public_key,
        reference,
        pin: authDetails?.otp
      })
    );
  };

  const submitOtpForm = useRef(e => {
    // Enter key triggers the authorization
    if (e.keyCode === 13) {
      const submitButton = submitButtonRef.current;
      if (!submitButton.classList.contains('disabled')) {
        submitButton.click();
      }
      return;
    }
  });

  const resetErrorMessage = useRef(() => {
    update({
      isOtpIncorrect: false,
      errorMessage: ''
    });
    newOtpSessionRef.current = false;
  });

  const authorizeOtp = () => {
    if (authDetails?.otp.length === 0 || otpState.disabled) return;
    if (isOtpIncorrect && newOtpSessionRef.current) {
      resetErrorMessage.current();
    }
    processPayment();
    setOtpState(prevOtpState => ({
      ...prevOtpState,
      disabled: true
    }));
  };

  useEffect(() => {
    const submitOtp = submitOtpForm.current;
    window.addEventListener('keydown', submitOtp);

    auth && document.getElementById('mobile-money-otp').focus();
    return () => window.removeEventListener('keydown', submitOtp);
  }, [auth]);

  useEffect(() => {
    if (isOtpIncorrect) {
      // Activates the retry state for a new mobile money number
      if (!otpState.activeRetry) {
        setOtpState(prevOtpState => ({ ...prevOtpState, activeRetry: true }));
      }
      setTimeout(() => document.querySelector('.details-error')?.focus());
      setTimeout(() => (newOtpSessionRef.current = true));
    }
  }, [isOtpIncorrect, update, otpState.activeRetry]);

  useEffect(() => {
    if (isOtpIncorrect && newOtpSessionRef.current) {
      resetErrorMessage.current();
    }
  }, [authDetails?.otp, isOtpIncorrect]);

  const sendRequest = () => {
    if (resendLimitExceeded) {
      return <p>Resend Limit exceeded</p>;
    }
    if (time?.text === 'expired') {
      return (
        <div>
          <button className="kpy-btn--transparent" onClick={retryRequest}>
            <img src={timeIcon} alt="time icon" aria-hidden height={12} />
            &nbsp;
            <p style={{ cursor: 'pointer' }} className="green-text">
              Resend request
            </p>
          </button>
        </div>
      );
    }

    return (
      <div>
        <img src={timeIcon} alt="time icon" aria-hidden height={12} />
        &nbsp;
        <p>Resend Request? Please wait {time?.text}</p>
      </div>
    );
  };

  return !auth ? (
    <div className="kpy-col__pay-form --mobile-money">
      <section className="channel_container">
        <p>
          <strong>Enter your Mobile money PIN to authorize this payment</strong>
        </p>
        <p>A request form has been sent to your phone, please enter your PIN to authorize this payment.</p>
        {canResendStk && sendRequest()}
      </section>
      <section className="details-footer">
        <p>You will get a confirmation once we receive your payment.</p>
      </section>
      <button className="kpy-col-btn" onClick={() => setAuth(true)}>
        <img src={lock} alt="lock icon" aria-hidden />
        <span>Enter PIN</span>
      </button>
    </div>
  ) : (
    <div className="kpy-col__pay-form --centered">
      <p className="details-head" id="mobile-money-otp-message">
        Enter your Mobile Money pin to authorize this payment.
      </p>
      {isOtpIncorrect && (
        <p className="details-error" tabIndex="-1">
          {errorMessage || 'The OTP entered is invalid. Please try typing it again.'}
        </p>
      )}
      <section className="channel_container">
        <div className="kpy-col-input-field">
          <label htmlFor="mobile-money-otp" className="kpy-col-label text-left">
            Enter Mobile Money PIN (OTP)
          </label>
          <input
            aria-label="mobile-money-otp"
            aria-describedby="mobile-money-otp-message"
            className="kpy-col-input --mobile-money-details otp_input"
            name="auth"
            id="mobile-money-otp"
            type="text"
            pattern={otpRegex}
            inputMode="numeric"
            maxLength={10}
            value={authDetails?.otp}
            onChange={event => {
              const value = event.target.value;
              const validChars = /^$|^[\d]+$/.test(value || '');
              if (!validChars) return;
              setAuthDetails(prevAuthDetails => ({
                ...prevAuthDetails,
                otp: value
              }));
              setOtpState(prevOtpState => ({
                ...prevOtpState,
                disabled: false
              }));
            }}
            placeholder=""
          />
        </div>
      </section>
      <button
        className={`kpy-col-btn otp-form-btn ${authDetails?.otp.length === 0 || otpState.disabled ? 'disabled' : ''}`}
        ref={submitButtonRef}
        aria-disabled={authDetails?.otp.length === 0 || otpState.disabled}
        onClick={authorizeOtp}
      >
        <img src={lock} alt="lock icon" aria-hidden />
        <span>Authorize</span>
      </button>
    </div>
  );
}
