import React, { useRef, useLayoutEffect, useEffect, useCallback } from 'react';
import { modalStore } from '../state';
import setupPusher from '../state/pusher';
import storage from '../utils/storage';

import Bank from './bank';
import BankTransfer from './bank-transfer/home';
import Card from './card';
import MobileMoney from './mobile-money';
import SandboxTooltip from './shared/sandbox-tooltip';
import ToolTip from './shared/tooltip';

import {
  Mixpanel,
  capitalizeRemoveDash,
  environments,
  switchPaymentChannelImage,
  switchPaymentChannelImageType,
  smartTrack,
  identifyUser,
  trackEvent
} from '../utils';

function Accordion() {
  const {
    stage,
    bankDetails,
    currency,
    update,
    public_key,
    payment_reference,
    unique_reference,
    channels,
    default_channel,
    sandboxInstructions,
    customer,
    paymentLink,
    sessionAttempt,
    extraInstructions,
    extraInstructionsHeader,
    extraInstructionsfooterText,
    showExtraInstructionsOnRender
  } = modalStore();
  const activeChannels: string[] = channels || ['bank_transfer'];
  const paymentSession = useRef(false);
  const sessionlimit = process.env.VITE_SESSION_ATTEMPT_LIMIT || 5;
  useLayoutEffect(() => {
    update({ page: 'Payment Method' });
  }, []);

  useEffect(() => {
    const storedData = storage.getItem('titan');
    const findIndex = storedData.findIndex(item => item.email === customer.email && item.id === paymentLink?.merchant_id);
    if (findIndex !== -1) {
      return update({
        browser_card_token: storedData[findIndex]?.token
      });
    }
  }, []);

  useEffect(() => {
    if (customer.email) {
      identifyUser(customer.email, unique_reference);
    }
  }, [customer.email]);

  const activatePaymentSession = () => (paymentSession.current = true);

  const initializePaymentChannel = useCallback(
    (channel: string) => {
      setupPusher(unique_reference, update);
      Mixpanel.register({
        'Payment Method': capitalizeRemoveDash(channel)
      });
      Mixpanel.track('Payment Method Selected', null);
      smartTrack('Payment Method Selected', switchPaymentChannelImage(currency)[channel as keyof switchPaymentChannelImageType][1]);
      trackEvent(`Payment Method Selected: ${switchPaymentChannelImage(currency)[channel as keyof switchPaymentChannelImageType][1]}`);
      if (channel === 'bank_transfer') {
        update({
          stage: 'bank_transfer',
          hideHeading: false,
          buttonAction: 'helpActions'
        });
      }
      if (channel === 'card') {
        update({
          stage: channel,
          hideHeading: false,
          buttonAction: 'changePayment'
        });
        activatePaymentSession();
      }
      if (channel === 'mobile_money') {
        update({
          stage: channel,
          mobileMoney: {
            stage: channel
          },
          hideHeading: false,
          buttonAction: 'changePayment'
        });
        activatePaymentSession();
      }
      if (channel === 'pay_with_bank') {
        update({
          stage: channel,
          hideHeading: false,
          buttonAction: 'helpActions'
        });
        activatePaymentSession();
      }
    },
    [bankDetails, payment_reference, update, public_key]
  );

  useEffect(() => {
    if (default_channel && stage === 'init' && !paymentSession.current) {
      initializePaymentChannel(default_channel);
    }
  }, [stage, default_channel, initializePaymentChannel]);

  const renderAccordion = () => {
    switch (stage) {
      case 'mobile_money':
        return <MobileMoney />;
      case 'pay_with_bank':
      case 'bank_help_actions':
        return <Bank />;
      case 'bank_transfer':
      case 'bank_transfer_help_actions':
        return <BankTransfer />;
      case 'card':
        return <Card />;
      default:
        return (
          <>
            <div className="kpy-col__accordion">
              <p className="accordion-title">How would you like to pay?</p>
              <div className="payment-options" data-testid="payment-options">
                {activeChannels.map(each => (
                  <div
                    key={each}
                    className="payment-channel"
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      if (sessionAttempt > sessionlimit) {
                        update({
                          modalState: 'session-end'
                        });
                        return;
                      }
                      initializePaymentChannel(each);
                    }}
                  >
                    <img src={switchPaymentChannelImage(currency)[each as keyof switchPaymentChannelImageType][0] || ''} alt="" />
                    <span>{switchPaymentChannelImage(currency)[each as keyof switchPaymentChannelImageType][1]}</span>
                    <ToolTip type={each} currency={currency} />
                  </div>
                ))}
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <>
      {renderAccordion()}
      {process.env.VITE_ENV_TYPE === environments.TEST && sandboxInstructions && <SandboxTooltip content={sandboxInstructions} />}
      {extraInstructions && (
        <SandboxTooltip
          content={extraInstructions}
          header={extraInstructionsHeader}
          footerText={extraInstructionsfooterText}
          openAutomaticaly={showExtraInstructionsOnRender}
        />
      )}
    </>
  );
}

export default Accordion;
